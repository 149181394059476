var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "row justify-content-center mb-4"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_c('div', {
    staticClass: "page-next-level"
  }, [_vm.logo ? _c('div', [_c('img', {
    staticStyle: {
      "background": "white"
    },
    attrs: {
      "src": _vm.logo,
      "alt": "",
      "width": "300"
    }
  }), _c('label', {
    staticClass: "upload-photo btn btn-primary upload-btn",
    attrs: {
      "for": "upload-photo"
    }
  }, [_vm._v(_vm._s(_vm.$t("org.logo")))]), _c('input', {
    attrs: {
      "type": "file",
      "name": "photo",
      "id": "upload-photo",
      "accept": "image/*"
    },
    on: {
      "change": function ($event) {
        return _vm.filesChange($event.target.files);
      }
    }
  })]) : _vm._e(), !_vm.logo ? _c('div', [_c('h4', {
    staticClass: "title text-black",
    staticStyle: {
      "text-transform": "capitalize"
    }
  }, [_vm._v(" " + _vm._s(_vm.organization.name) + " ")]), _c('label', {
    staticClass: "upload-photo btn btn-primary upload-btn",
    attrs: {
      "for": "upload-photo"
    }
  }, [_vm._v(_vm._s(_vm.$t("org.logo")))]), _c('input', {
    attrs: {
      "type": "file",
      "name": "photo",
      "id": "upload-photo",
      "accept": "image/*"
    },
    on: {
      "change": function ($event) {
        return _vm.filesChange($event.target.files);
      }
    }
  })]) : _vm._e()])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6 form-group",
    class: {
      'has-error': _vm.$v.organization.name.$error
    }
  }, [_c('label', {
    staticStyle: {
      "text-transform": "capitalize"
    },
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("name")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _vm.userRoleVal == 'Staff' ? _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.organization.name.$model,
      expression: "$v.organization.name.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.$v.organization.name.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.organization.name, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }) : _vm._e(), _vm.$v.organization.name.$error && !_vm.$v.organization.name.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("name")) + " " + _vm._s(_vm.organization.type) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e(), _vm.userRoleVal != 'Staff' ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.user.name))]) : _vm._e()]), _c('div', {
    staticClass: "col-md-6 form-group",
    class: {
      'has-error': _vm.$v.organization.short_name.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("shortname")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.organization.short_name.$model,
      expression: "$v.organization.short_name.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.$v.organization.short_name.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.organization.short_name, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.organization.short_name.$error && !_vm.$v.organization.short_name.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("shortname")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])]), _vm.organizationType == 'Awaris' ? _c('div', {
    staticClass: "row"
  }, [_vm.organizationType != 'Individu' ? _c('div', {
    staticClass: "form-group col-md-6",
    class: {
      'has-error': _vm.$v.organization.account_type.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "account_type"
    }
  }, [_vm._v(_vm._s(_vm.$t("account-type")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.organization.account_type.$model,
      expression: "$v.organization.account_type.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": "account_type"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.organization.account_type, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("-- " + _vm._s(_vm.$t("choose.account")) + " --")]), _c('option', {
    attrs: {
      "value": "Biasa"
    }
  }, [_vm._v(_vm._s(_vm.$t("normal")))]), _c('option', {
    attrs: {
      "value": "Financial"
    }
  }, [_vm._v(_vm._s(_vm.$t("financial")))]), _c('option', {
    attrs: {
      "value": "Non-financial"
    }
  }, [_vm._v(_vm._s(_vm.$t("non-financial")))])]), _vm.$v.organization.account_type.$error && !_vm.$v.organization.account_type.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("account-type")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "col-md-6 form-group",
    class: {
      'has-error': _vm.$v.organization.address.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("address")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.organization.address.$model,
      expression: "$v.organization.address.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.$v.organization.address.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.organization.address, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.organization.address.$error && !_vm.$v.organization.address.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("address")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "col-md-6 form-group",
    class: {
      'has-error': _vm.$v.organization.postcode.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("postcode")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.lazy.trim",
      value: _vm.$v.organization.postcode.$model,
      expression: "$v.organization.postcode.$model",
      modifiers: {
        "lazy": true,
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "number",
      "id": "postcode"
    },
    domProps: {
      "value": _vm.$v.organization.postcode.$model
    },
    on: {
      "blur": [function ($event) {
        return _vm.getPostcode(1);
      }, function ($event) {
        return _vm.$forceUpdate();
      }],
      "change": function ($event) {
        _vm.$set(_vm.$v.organization.postcode, "$model", $event.target.value.trim());
      }
    }
  }), _vm.$v.organization.postcode.$error && !_vm.$v.organization.postcode.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.postcode")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "col-md-6 form-group",
    class: {
      'has-error': _vm.$v.organization.city.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("city")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.organization.city.$model,
      expression: "$v.organization.city.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "readonly": ""
    },
    domProps: {
      "value": _vm.$v.organization.city.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.organization.city, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.organization.city.$error && !_vm.$v.organization.city.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.city")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "col-md-6 form-group",
    class: {
      'has-error': _vm.$v.organization.state.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("state")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.organization.state.$model,
      expression: "$v.organization.state.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "readonly": ""
    },
    domProps: {
      "value": _vm.$v.organization.state.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.organization.state, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.organization.state.$error && !_vm.$v.organization.state.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("state")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_vm.organizationType == 'Individu' ? _c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("mobile-no")))]) : _vm._e(), _vm.organizationType != 'Individu' ? _c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("office-no")))]) : _vm._e(), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.organization.phone_number,
      expression: "organization.phone_number"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.organization.phone_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.organization, "phone_number", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "col-md-6 form-group",
    class: {
      'has-error': _vm.$v.organization.email.$error
    }
  }, [_vm.organizationType == 'Individu' ? _c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("email")))]) : _vm._e(), _vm.organizationType != 'Individu' ? _c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("office-email")))]) : _vm._e(), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "lowercase",
      rawName: "v-lowercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.organization.email.$model,
      expression: "$v.organization.email.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.$v.organization.email.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.organization.email, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), !_vm.$v.organization.email.email ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.invalid-email")) + " ")]) : _vm._e(), _vm.$v.organization.email.$error && !_vm.$v.organization.email.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.email")) + " ")]) : _vm._e()]), _vm.organizationType != 'Individu' ? _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("website")))]), _c('input', {
    directives: [{
      name: "lowercase",
      rawName: "v-lowercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.organization.website,
      expression: "organization.website"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.organization.website
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.organization, "website", $event.target.value);
      }
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "col-md-6 form-group",
    class: {
      'has-error': _vm.$v.organization.status.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("status")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.organization.status.$model,
      expression: "$v.organization.status.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.organization.status, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v(_vm._s(_vm.$t("active")))]), _c('option', {
    attrs: {
      "value": "0"
    }
  }, [_vm._v(_vm._s(_vm.$t("inactive")))])]), _vm.$v.organization.status.$error && !_vm.$v.organization.status.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" Status " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])]) : _vm._e(), _vm.organizationType != 'Awaris' ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group col-md-6",
    class: {
      'has-error': _vm.$v.organization.code.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "code"
    }
  }, [_vm._v(_vm._s(_vm.$t("code")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.organization.code.$model,
      expression: "$v.organization.code.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.$v.organization.code.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.organization.code, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.organization.code.$error && !_vm.$v.organization.code.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("code")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]), _vm.organizationType != 'Individu' ? _c('div', {
    staticClass: "form-group col-md-6",
    class: {
      'has-error': _vm.$v.organization.account_type.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "account_type"
    }
  }, [_vm._v(_vm._s(_vm.$t("account-type")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.organization.account_type.$model,
      expression: "$v.organization.account_type.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": "account_type"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.organization.account_type, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("financial")) + " "), _c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("-- " + _vm._s(_vm.$t("choose.account")) + " --")]), _c('option', {
    attrs: {
      "value": "Biasa"
    }
  }, [_vm._v(_vm._s(_vm.$t("normal")))]), _c('option', {
    attrs: {
      "value": "Financial"
    }
  }, [_vm._v(_vm._s(_vm.$t("financial")))]), _c('option', {
    attrs: {
      "value": "Non-financial"
    }
  }, [_vm._v(_vm._s(_vm.$t("non-financial")))])]), _vm.$v.organization.account_type.$error && !_vm.$v.organization.account_type.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("account-type")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "col-md-6 form-group",
    class: {
      'has-error': _vm.$v.organization.address.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("address")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.organization.address.$model,
      expression: "$v.organization.address.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.$v.organization.address.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.organization.address, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.organization.address.$error && !_vm.$v.organization.address.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("address")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "col-md-6 form-group",
    class: {
      'has-error': _vm.$v.organization.postcode.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("postcode")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.lazy.trim",
      value: _vm.$v.organization.postcode.$model,
      expression: "$v.organization.postcode.$model",
      modifiers: {
        "lazy": true,
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "number",
      "id": "postcode"
    },
    domProps: {
      "value": _vm.$v.organization.postcode.$model
    },
    on: {
      "blur": [function ($event) {
        return _vm.getPostcode(1);
      }, function ($event) {
        return _vm.$forceUpdate();
      }],
      "change": function ($event) {
        _vm.$set(_vm.$v.organization.postcode, "$model", $event.target.value.trim());
      }
    }
  }), _vm.$v.organization.postcode.$error && !_vm.$v.organization.postcode.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.postcode")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "col-md-6 form-group",
    class: {
      'has-error': _vm.$v.organization.city.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("city")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.organization.city.$model,
      expression: "$v.organization.city.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "readonly": ""
    },
    domProps: {
      "value": _vm.$v.organization.city.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.organization.city, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.organization.city.$error && !_vm.$v.organization.city.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.city")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "col-md-6 form-group",
    class: {
      'has-error': _vm.$v.organization.state.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("state")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.organization.state.$model,
      expression: "$v.organization.state.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "readonly": ""
    },
    domProps: {
      "value": _vm.$v.organization.state.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.organization.state, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.organization.state.$error && !_vm.$v.organization.state.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("state")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_vm.organizationType == 'Individu' ? _c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("mobile-no")))]) : _vm._e(), _vm.organizationType != 'Individu' ? _c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("office-no")))]) : _vm._e(), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.organization.phone_number,
      expression: "organization.phone_number"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.organization.phone_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.organization, "phone_number", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "col-md-6 form-group",
    class: {
      'has-error': _vm.$v.organization.email.$error
    }
  }, [_vm.organizationType == 'Individu' ? _c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("email")))]) : _vm._e(), _vm.organizationType != 'Individu' ? _c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("office-email")))]) : _vm._e(), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "lowercase",
      rawName: "v-lowercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.organization.email.$model,
      expression: "$v.organization.email.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.$v.organization.email.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.organization.email, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), !_vm.$v.organization.email.email ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.invalid-email")) + " ")]) : _vm._e(), _vm.$v.organization.email.$error && !_vm.$v.organization.email.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.email")) + " ")]) : _vm._e()]), _vm.organizationType != 'Individu' ? _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("website")))]), _c('input', {
    directives: [{
      name: "lowercase",
      rawName: "v-lowercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.organization.website,
      expression: "organization.website"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.organization.website
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.organization, "website", $event.target.value);
      }
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "col-md-6 form-group",
    class: {
      'has-error': _vm.$v.organization.status.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("status")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.organization.status.$model,
      expression: "$v.organization.status.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.organization.status, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v(_vm._s(_vm.$t("active")))]), _c('option', {
    attrs: {
      "value": "0"
    }
  }, [_vm._v(_vm._s(_vm.$t("inactive")))])]), _vm.$v.organization.status.$error && !_vm.$v.organization.status.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" Status " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])]) : _vm._e(), _vm.organizationType != 'Awaris' ? _c('div', {
    staticClass: "row"
  }, [_vm.organizationType != 'Individu' ? _c('div', {
    staticClass: "col-md-6 form-group",
    class: {
      'has-error': _vm.$v.organization.company_registration_number.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("org.reg-no")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.organization.company_registration_number.$model,
      expression: "$v.organization.company_registration_number.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.$v.organization.company_registration_number.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.organization.company_registration_number, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.organization.company_registration_number.$error && !_vm.$v.organization.company_registration_number.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("org.reg-no")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]) : _vm._e(), _vm.organizationType == 'Individu' ? _c('div', {
    staticClass: "col-md-6 form-group",
    class: {
      'has-error': _vm.$v.organization.mykad_number.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("mykad")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('the-mask', {
    staticClass: "form-control",
    attrs: {
      "id": "ic_number",
      "mask": ['######-##-####']
    },
    model: {
      value: _vm.$v.organization.mykad_number.$model,
      callback: function ($$v) {
        _vm.$set(_vm.$v.organization.mykad_number, "$model", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "$v.organization.mykad_number.$model"
    }
  }), _vm.$v.organization.mykad_number.$error && !_vm.$v.organization.mykad_number.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.mykad")) + " ")]) : _vm._e()], 1) : _vm._e(), _vm.organizationType != 'Awaris' && _vm.organizationType != 'Individu' ? _c('div', {
    staticClass: "col-md-6 form-group",
    class: {
      'has-error': _vm.$v.organization.founder_name.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("org.founder")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.organization.founder_name.$model,
      expression: "$v.organization.founder_name.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.$v.organization.founder_name.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.organization.founder_name, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.organization.founder_name.$error && !_vm.$v.organization.founder_name.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("org.founder")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]) : _vm._e(), _vm.organizationType != 'Awaris' && _vm.organizationType != 'Individu' ? _c('div', {
    staticClass: "col-md-6 form-group",
    class: {
      'has-error': _vm.$v.organization.pic_phone_number.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("pic-telno")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.organization.pic_phone_number.$model,
      expression: "$v.organization.pic_phone_number.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.$v.organization.pic_phone_number.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.organization.pic_phone_number, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.organization.pic_phone_number.$error && !_vm.$v.organization.pic_phone_number.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("pic-telno")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]) : _vm._e(), _vm.organizationType != 'Awaris' && _vm.organizationType != 'Bank' ? _c('div', {
    staticClass: "col-md-6 form-group",
    class: {
      'has-error': _vm.$v.organization.bank_account_number.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("bank-account")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.organization.bank_account_number.$model,
      expression: "$v.organization.bank_account_number.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.$v.organization.bank_account_number.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.organization.bank_account_number, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.organization.bank_account_number.$error && !_vm.$v.organization.bank_account_number.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("bank-account")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]) : _vm._e(), _vm.organizationType == 'Bank' ? _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("bank-account")))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.organization.bank_account_number,
      expression: "organization.bank_account_number"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.organization.bank_account_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.organization, "bank_account_number", $event.target.value);
      }
    }
  })]) : _vm._e(), _vm.organizationType != 'Awaris' && _vm.organizationType != 'Bank' ? _c('div', {
    staticClass: "col-md-6 form-group",
    class: {
      'has-error': _vm.$v.organization.bank_account_holder.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("acc-holder")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.organization.bank_account_holder.$model,
      expression: "$v.organization.bank_account_holder.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.$v.organization.bank_account_holder.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.organization.bank_account_holder, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.organization.bank_account_holder.$error && !_vm.$v.organization.bank_account_holder.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("acc-holder")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]) : _vm._e(), _vm.organizationType == 'Bank' ? _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("acc-holder")))]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.organization.bank_account_holder,
      expression: "organization.bank_account_holder"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.organization.bank_account_holder
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.organization, "bank_account_holder", $event.target.value);
      }
    }
  })]) : _vm._e(), _vm.organizationType != 'Awaris' && _vm.organizationType != 'Bank' ? _c('div', {
    staticClass: "col-md-6 form-group",
    class: {
      'has-error': _vm.$v.organization.bank_name.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("bankname")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.organization.bank_name.$model,
      expression: "$v.organization.bank_name.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.$v.organization.bank_name.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.organization.bank_name, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.organization.bank_name.$error && !_vm.$v.organization.bank_name.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("bankname")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]) : _vm._e(), _vm.organizationType == 'Bank' ? _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("bankname")))]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.organization.bank_name,
      expression: "organization.bank_name"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.organization.bank_name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.organization, "bank_name", $event.target.value);
      }
    }
  })]) : _vm._e()]) : _vm._e(), _vm.userRole == 'Staff' ? _c('div', {
    staticClass: "row"
  }, [_c('hr'), _c('div', {
    staticClass: "col-md-6 form-group",
    class: {
      'has-error': _vm.$v.organization.allow_payment_gateway.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("org.online")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.organization.allow_payment_gateway.$model,
      expression: "$v.organization.allow_payment_gateway.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.organization.allow_payment_gateway, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v(_vm._s(_vm.$t("active")))]), _c('option', {
    attrs: {
      "value": "0"
    }
  }, [_vm._v(_vm._s(_vm.$t("inactive")))])]), _vm.$v.organization.allow_payment_gateway.$error && !_vm.$v.organization.allow_payment_gateway.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("online-payment-status")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "col-md-6 form-group",
    class: {
      'has-error': _vm.$v.organization.allow_cash_payment.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("org.cash")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.organization.allow_cash_payment.$model,
      expression: "$v.organization.allow_cash_payment.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.organization.allow_cash_payment, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v(_vm._s(_vm.$t("active")))]), _c('option', {
    attrs: {
      "value": "0"
    }
  }, [_vm._v(_vm._s(_vm.$t("inactive")))])]), _vm.$v.organization.allow_cash_payment.$error && !_vm.$v.organization.allow_cash_payment.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("cash-payment-status")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "col-md-6 form-group",
    class: {
      'has-error': _vm.$v.organization.allow_direct_payment.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("accept-direct-payment")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.organization.allow_direct_payment.$model,
      expression: "$v.organization.allow_direct_payment.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.organization.allow_direct_payment, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v(_vm._s(_vm.$t("active")))]), _c('option', {
    attrs: {
      "value": "0"
    }
  }, [_vm._v(_vm._s(_vm.$t("inactive")))])]), _vm.$v.organization.allow_direct_payment.$error && !_vm.$v.organization.allow_direct_payment.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("direct-payment-status")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "col-md-6 form-group",
    class: {
      'has-error': _vm.$v.organization.allow_atb_payment.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("accept-atb")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.organization.allow_atb_payment.$model,
      expression: "$v.organization.allow_atb_payment.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.organization.allow_atb_payment, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v(_vm._s(_vm.$t("active")))]), _c('option', {
    attrs: {
      "value": "0"
    }
  }, [_vm._v(_vm._s(_vm.$t("inactive")))])]), _vm.$v.organization.allow_atb_payment.$error && !_vm.$v.organization.allow_atb_payment.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("direct-payment-status")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "col-md-6 form-group",
    class: {
      'has-error': _vm.$v.organization.allow_loan_payment.$error
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("accept-funding")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.organization.allow_loan_payment.$model,
      expression: "$v.organization.allow_loan_payment.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.organization.allow_loan_payment, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v(_vm._s(_vm.$t("active")))]), _c('option', {
    attrs: {
      "value": "0"
    }
  }, [_vm._v(_vm._s(_vm.$t("inactive")))])]), _vm.$v.organization.allow_loan_payment.$error && !_vm.$v.organization.allow_loan_payment.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("funding-status")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])]) : _vm._e(), _vm.organizationType == 'Individu' ? _c('div', {
    staticClass: "row"
  }, [_c('hr'), _c('div', {
    staticClass: "col-md-12 mb-4"
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("user.info-user")))])]), _c('div', {
    staticClass: "form-group col-md-6",
    class: {
      'has-error': _vm.$v.form.username.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "username"
    }
  }, [_vm._v(_vm._s(_vm.$t("username")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.username.$model,
      expression: "$v.form.username.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.$v.form.username.$model
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "space", 32, $event.key, [" ", "Spacebar"])) { return null; }
        $event.preventDefault();
      },
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.username, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.username.$error && !_vm.$v.form.username.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.username")) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("gender")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.gender.$model,
      expression: "$v.form.gender.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": "gender"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.form.gender, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("-- " + _vm._s(_vm.$t("choose.gender")) + " --")]), _vm._l(_vm.genders, function (gender, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": gender.split(':')[0]
      }
    }, [_vm._v(" " + _vm._s(gender.split(":")[1]) + " ")]);
  })], 2), _vm.$v.form.gender.$error && !_vm.$v.form.gender.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("gender")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])]) : _vm._e(), _c('hr'), _c('div', {
    staticClass: "row"
  }, [_vm.organizationType != 'Individu' ? _c('div', {
    staticClass: "col-md-6 text-left"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.back
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")])]) : _vm._e(), _vm.organizationType == 'Individu' ? _c('div', {
    staticClass: "col-md-6 text-left"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.backIndividu
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")])]) : _vm._e(), _c('div', {
    staticClass: "col-md-6 text-right"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.saveOrganization
    }
  }, [_c('i', {
    staticClass: "mdi mdi-file-document"
  }), _vm._v(" " + _vm._s(_vm.$t("save")) + " "), _vm.isSubmit ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _vm._e()])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }